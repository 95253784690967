<template>
  <router-view/>
</template>

<style lang="scss">
@font-face {
  font-family: "UAF Sans";
  src: local("UAF Sans"),
  url(@/assets/fonts/UAF_Sans/UAFSans-Regular.ttf) format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "UAF Sans Bold";
  src: local("UAF Sans Bold"),
  url(@/assets/fonts/UAF_Sans/UAFSans-Bold.ttf) format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "UAF Sans SB";
  src: local("UAF Sans SB"),
  url(@/assets/fonts/UAF_Sans/UAFSans-SemiBold.ttf) format("truetype");
  font-display: swap;
}

html {
  scroll-behavior: smooth;
}
body {
  overflow-x: hidden;
}

* {
  margin: 0;
  padding: 0;
  font-family: "UAF Sans";
  color: #6A653A;
}

.container {
  max-width: 1128px;
  margin: 0 auto;
  width: 100vw;

  @media (max-width: 767px) {
    width: auto;
  }

  @media (min-width: 768px) and (max-width: 1127px) {
    max-width: 1127px;
    width: 90%;
  }
}

h1 {
  font-family: "UAF Sans SB";
  font-size: 64px;
  line-height: 76.8px;

  @media (max-width: 767px) {
    font-size: 32px;
    line-height: 38.4px;
    text-align: center;
  }
}

h2 {
  font-family: "UAF Sans SB";
  font-size: 48px;
  line-height: 57.6px;
  text-transform: uppercase;
  color: #6A653A;
  max-width: 210px;
  margin-bottom: 45px;

  @media (max-width: 767px) {
    font-size: 36px;
    line-height: 43.2px;
  }
}

h3 {
  font-size: 32px;
  font-weight: 500;
  line-height: 38.4px;
  text-align: left;
  color: #6A653A;
  @media (max-width: 767px) {
    font-size: 24px;
    line-height: 28.8px;
  }
}

h4 {
  font-size: 28px;
  font-weight: 500;
  line-height: 33.6px;
  max-width: 328px;
  font-family: "UAF Sans SB";

  @media (max-width: 767px) {
    font-size: 22px;
    line-height: 26.4px;
  }
}

h5 {
  font-size: 22px;
  font-weight: 700;
  line-height: 26.4px;
  @media (max-width: 767px) {
    font-size: 36px;
    line-height: 43.2px;
  }
}
</style>
