<template>
  <button @click="scrollToAnchor" class="button__connect">Долучитися</button>
</template>

<script>
export default {
  name: 'button-connect',
  methods: {
    scrollToAnchor() {
      const element = document.getElementById('form');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      } else {
        window.location.href = '/#form';
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.button {
  &__connect {
    cursor: pointer;
    width: 264px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    color: white;
    font-size: 18px;
    font-family: "UAF Sans SB";
    background: #F39200;
    border: none;
  }
}
</style>