<template>
  <header class="header">
    <div class="container desktop">
      <div class="header__logo">
        <img src="@/assets/images/logo_mf.png" alt="logo" />
      </div>
      <nav class="header__nav">
        <ul>
          <li><a href="/#about">Про військо</a></li>
          <li><a href="/#structure">Структура</a></li>
          <li><a target="_blank" href="/df">DroneForce</a></li>
          <li><a href="/#footer">Контакти</a></li>
        </ul>
      </nav>
      <button-connect />
    </div>
    <div class="container mobile">
      <div class="header__logo">
        <img src="@/assets/images/logo_mf.png" alt="logo" />
      </div>
      <img @click="isOpenMenu = !isOpenMenu" class="js-menu-mob" src="@/assets/images/menu.svg" alt="burger" />
      <div
        class="header__mob-menu"
        :class="{'open': isOpenMenu}"
      >
        <div class="header__mob-menu__top">
          <div class="header__mob-menu--header">
            <img class="header__mob-menu--logo" src="@/assets/images/logo-mf-menu.svg" alt="logo" />
            <img
              @click="isOpenMenu = !isOpenMenu"
              class="header__mob-menu--close"
              src="@/assets/images/close_btn.svg"
              alt="icon"
            />
          </div>
          <nav class="header__mob-menu--nav">
            <ul>
              <li><a href="#about">Про війська</a></li>
              <li><a href="#structure">Структура</a></li>
              <li><a href="#df">DroneForce</a></li>
              <li><a href="#form">Анкета</a></li>
            </ul>
          </nav>
        </div>
        <div class="header__mob-menu--bottom">
          <div class="header__mob-menu--contact-item">
            <div
              class="header__mob-menu--contact-contact"
              v-for="phone in phoneArr"
              :key="phone.id"
            >
              <img class="header__mob-menu--contact-icon" src="@/assets/images/phone.svg" alt="icon" />
              <a :href="'tel:' + phone.phone">{{ phone.phone }}<br><span style="font-size: 9px">{{ phone.title }}</span></a>
            </div>
            <p class="header__mob-menu--contact-label">Для медіа:</p>
            <div
              class="header__mob-menu--contact-contact"
            >
              <img class="header__mob-menu--contact-icon" src="@/assets/images/email.svg" alt="icon" />
              <a href="mailto:press.ksv@post.mil.gov.ua">press.ksv@post.mil.gov.ua</a>
            </div>
          </div>
          <p class="header__mob-menu--contact-label">
            Корисні посилання:
          </p>
          <div class="header__mob-menu--contact-social">
            <a target="_blank" href="https://www.mil.gov.ua/">
              <img class="header__mob-menu--contact-link" src="@/assets/images/mou.svg" alt="icon" />
            </a>
            <a target="_blank" href="https://www.zsu.gov.ua/">
              <img class="header__mob-menu--contact-link" src="@/assets/images/zsu.svg" alt="icon" />
            </a>
          </div>
          <div class="header__mob-menu--contact-media">
            <p class="header__mob-menu--contact-label">Соціальні мережі:</p>
            <div style="display: flex;gap:5px">
              <a target="_blank" href="https://www.facebook.com/UALandForces"><img class="header__mob-menu--contact-icon" src="@/assets/images/facebook-true.png" alt="icon" /></a>
              <a target="_blank" href="https://www.instagram.com/ukrainianlandforces"><img class="header__mob-menu--contact-icon" src="@/assets/images/instagram-true.png" alt="icon" /></a>
              <a target="_blank" href="https://t.me/landforcesofukraine"><img class="header__mob-menu--contact-icon" src="@/assets/images/telegram-true.png" alt="icon" /></a>
              <a target="_blank" href="https://youtube.com/@landforcesofukraine"><img class="header__mob-menu--contact-icon" src="@/assets/images/youtube-true.png" alt="icon" /></a>
              <a target="_blank" href="https://www.tiktok.com/@landforcesofukraineoff"><img class="header__mob-menu--contact-icon" src="@/assets/images/tiktok-true.png" alt="icon" /></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import ButtonConnect from "@/components/UI/CustomButtonAdd.vue";

export default {
  name: "custom-header",
  components: {ButtonConnect},
  data() {
    return {
      isOpenMenu: false,
      phoneArr: [
        {
          phone: '0 800 500 410',
          title: '(загальні питання)',
        },
        {
          phone: '0 800 301 314',
          title: '(рекрутинг)',
        },
        {
          phone: '0 800 301 937',
          title: '(питання комплектування)',
        },
      ],
    };
  }
}
</script>

<style lang="scss" scoped>
.header {
  position: absolute;
  top: 28px;
  left: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 2;

  &__mob-menu {
    width: 198px;
    height: 576px;
    position: absolute;
    top: -14px;
    right: 0;
    transform: translateY(-600px);
    transition: 1s;
    border-radius: 10px 0 10px 10px;
    border: 1px solid #211f1a;
    background: #F3F3EF;
    padding: 23px 15px 20px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    visibility: hidden;

    &.open {
      transform: translateY(0);
      transition: 1s;
      visibility: visible;
    }

    &--header {
      display: flex;
      justify-content: space-between;
    }

    &--logo {
      width: 58px;
    }

    &--nav {
      ul {
        list-style-type: none;
        display: flex;
        flex-direction: column;
        gap: 15px;
        margin-top: 30px;

        li {
          a {
            font-size: 18px;
            font-weight: 400;
            line-height: 21.6px;
            text-align: left;
            text-decoration: none;
          }
        }
      }
    }

    &--contact-item {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    &--contact-contact {
      display: flex;gap: 5px;
      font-size: 12px;
      align-items: center;
    }

    &--contact-label {
      margin: 14px 0 5px;
    }

    &--contact-social {
      display: flex;
      gap: 14px;
    }

    &--contact-icon {
      width: 14px;
      margin-right: 5px;
    }
  }

  &:has(.mobile) {
    justify-content: space-between;
    top: 14px;
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px;
  }

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.mobile {
      width: 100%;
    }
  }

  &__logo {
    img {
      max-width: 154px;
    }

    .mobile & {
      img {
        max-width: 121px;
      }
    }
  }

  &__nav {
    ul {
      display: flex;
      gap: 25px;
      list-style-type: none;

      li {
        width: 130px;
      }

      a {
        color: white;
        text-decoration: none;
        font-size: 20px;
        font-weight: 400;
        transition: font-family 0.3s ease-in-out;

        &:hover {
          font-family: "UAF Sans SB";
        }
      }
    }
  }
}
</style>