<template>
  <section id="structure" class="structure">
    <div class="container">
      <h2>{{ title }}</h2>
      <div class="structure__list">
        <div
          class="structure__item"
          v-for="item in structArr"
          :key="item.id"
          :class="{'open-block': item.isOpen}"
        >
          <div
            @click="openBlock(item.id)"
            class="structure__title"
          >
            {{ item.name }}
          </div>
          <transition
            @enter="enter"
            @leave="leave"
          >
            <div
              v-if="item.isOpen"
              class="structure__description"
            >
              {{ item.description }}
            </div>
          </transition>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'structure-block',
  data() {
    return {
      title: 'Структура',
      structArr: [
        {
          name: 'Механізовані війська',
          description: 'Основа Сухопутних військ. Призначені для утримання зайнятих районів, рубежів і позицій, відбиття ударів противника, прориву оборони противника, розгрому його військ, захоплення важливих районів, рубежів і об’єктів. Діють також у складі морських та повітряних десантів. До складу механізованих  військ входять механізовані, мотопіхотні та піхотні з\'єднання та підрозділи.',
          isOpen: false,
          id: 1,
        },
        {
          name: 'Танкові війська',
          description: 'Головна ударна сила Сухопутних військ для виконання завдань в обороні та в наступі. Основу танкових військ сухопутної складової ЗСУ складають танкові бригади та танкові батальйони механізованих бригад.',
          isOpen: false,
          id: 2,
        },
        {
          name: 'Ракетні війська і артилерія',
          description: 'Головний засіб вогневого ураження противника Сухопутних військ. До складу ракетних військ і артилерії входять військові частини тактичних ракет, реактивної, гаубичної, гарматної та протитанкової артилерії, а також артилерійської розвідки. Абсолютна більшість уражень противника шляхом ракетних та артилерійських ударів - робота воїнів Сухопутних військ.',
          isOpen: false,
          id: 3,
        },
        {
          name: 'Війська ППО',
          description: 'Призначені для прикриття своїх військ та важливих об’єктів у всіх видах бойових дій (операціях), під час перегрупування (переміщення) та розташування військ на місці. Входять до складу загальновійськових з’єднань і частин та виконують завдання в єдиній системі протиповітряної оборони держави. \n',
          isOpen: false,
          id: 4,
        },
        {
          name: 'Армійська авіація',
          description: 'Виконує авіаційну підтримку загальновійськового бою. Частини і підрозділи армійської авіації ведуть розвідку, знищують бойову техніку та живу силу противника при обороні наших військ, здійснюють вогневу підтримку під час наступу чи контратаки, висаджують тактичний десант, доставляють у вказані райони бойову техніку та особовий склад, евакуйовують поранений особовий склад та виконують інші важливі завдання. Основне озброєння – вертольоти різних типів.',
          isOpen: false,
          id: 5,
        },
        {
          name: 'Спеціальні війська',
          description: 'Призначені для забезпечення бойової діяльності Сухопутних військ Збройних Сил України, які мають у своєму складі розвідувальні, інженерні, топографічні частини та підрозділи, військові частини зв’язку, радіохімічного та бактеріологічного захисту та радіоелектронної боротьби.',
          isOpen: false,
          id: 7,
        },
        {
          name: 'Військова освіта',
          description: 'У підпорядкуванні Сухопутних військ всі провідні вищі військові навчальні заклади України. Ми готуємо військову еліту для всіх складових Сил оборони. Випускники наших ВВНЗ – гордість української армії.',
          isOpen: false,
          id: 8,
        },
        {
          name: 'Військова підготовка',
          description: 'Більшість центрів підготовки у Збройних Силах – у підпорядкуванні Сухопутних військ. Ми навчаємо новобранців, злагоджуємо підрозділи і штаби рівня батальйону-бригади, відновлюємо підрозділи. Ми вивільняємо силу, яка звільняє Україну! ',
          isOpen: false,
          id: 9,
        },
        {
          name: 'Забезпечення',
          description: 'Підрозділи логістичного забезпечення вирішують завдання щодо організації експлуатації озброєння та військової техніки, забезпечення військ озброєнням та військовою технікою, матеріально-технічними засобами, харчування, лазне-прального обслуговування, розквартирування військ, експлуатацію об’єктів військової інфраструктури.',
          isOpen: false,
          id: 10,
        },
        {
          name: 'Безпілотні системи',
          description: 'Підрозділи безпілотних систем в Сухопутних військах – найбільш розвинуті в усій структурі Збройних Сил України. Окремі полки, батальйони та підрозділи БпС у більшості наших формувань виконують задачі з розвідки та ураження противника, доставлення боєприпасів, евакуації особового складу, озброєння та цінностей. У Сухопутних військах використовуються всі типи безпілотних літальних апаратів, а також багатофункціональні наземні системи.',
          isOpen: false,
          id: 11,
        },
        // {
        //   name: 'Комплектування та соціальна підтримка',
        //   description: 'Сухопутні війська здійснюють безпосереднє керівництво територіальними центрами комплектування та соціальної підтримки і контроль за їх діяльністю, узгоджуючи основні питання діяльності ТЦК та СП з відповідними структурними підрозділами Генерального штабу Збройних Сил та Міноборони. ',
        //   isOpen: false,
        //   id: 11,
        // },
      ],
    };
  },
  methods: {
    openBlock(id) {
      this.structArr = this.structArr.map(item => {
        item.isOpen = item.id === id ? !item.isOpen : false;
        return item;
      });
    },
    enter(el) {
      el.style.height = '0';
      el.style.paddingTop = '0';
      el.style.paddingBottom = '0';
      el.style.overflow = 'hidden';
      el.offsetHeight;
      el.style.transition = 'height 0.5s ease, padding 0.5s ease';
      el.style.height = el.scrollHeight + 'px';
      el.style.paddingTop = '';
      el.style.paddingBottom = '';
      document.querySelector('.open-block').style.marginBottom = el.scrollHeight + 50 + 'px';
    },
    leave(el) {
      el.style.height = el.scrollHeight + 'px';
      el.style.paddingTop = '';
      el.style.paddingBottom = '';
      el.offsetHeight; // force reflow
      el.style.transition = 'height 0.5s ease, padding 0.5s ease';
      el.style.height = '0';
      el.style.paddingTop = '0';
      el.style.paddingBottom = '0';
      document.querySelectorAll('.structure__item').forEach((el) => {
        el.style.marginBottom = '0px';
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.structure {
  padding: 70px 0 0;

  @media (max-width: 767px) {
    padding: 22px 20px;
  }

  .container {
    position: relative;
  }

  .open-block {
    .structure__title {
      background: #F3F3EF;
      position: relative;

      &::after {
        content: '';
        width: 2px;
        height: 16px;
        background: #6A653A;
        position: absolute;
        bottom: -18px;
        left: 48%;
      }
    }
  }

  &__list {
    display: flex;
    column-gap: 24px;
    row-gap: 20px;
    flex-wrap: wrap;
    justify-content: center;
  }

  &__item {
    transition: .6s;
    @media (max-width: 767px) {
      width: 100vw;
    }
  }

  &__title {
    max-width: 400px;
    padding: 26.5px 37px;
    border: 2px solid #6A653A;
    font-size: 24px;
    box-sizing: border-box;
    height: 82px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    @media (max-width: 767px) {
      padding: 10px 0;
      font-size: 17px;
      height: 55px;
    }
  }


    &__description {
      /* Стили для описания */
    overflow: hidden;
    position: absolute;
    left: 0;
    padding: 15px 15px;
    margin: 15px 0;
    border: 2px solid;
    background: #F3F3EF;
  }
}
</style>