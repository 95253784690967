<template>
  <custom-header />
  <section class="privacy">
    <div class="container">
      <h2>ПОЛІТИКА КОНФІДЕНЦІЙНОСТІ</h2>
      <p>Останнє оновлення: 9 вересня 2024 року</p>
      <p>Ця політика конфіденційності для сайту Сухопутних військ України ("ми", "нас" або "наші") описує, як і чому ми можемо отримувати, збирати, зберігати, використовувати та/або ділитися ("обробляти") вашою особистою інформацію, коли ви використовуєте наші послуги ("Послуги"), зокрема коли ви:</p>
      <ul>
        <li>Відвідуєте наш вебсайт за адресою <a class="disable-underline" href="https://landforces.mil.gov.ua">https://landforces.mil.gov.ua</a> або будь-який інший наш вебсайт, який містить посилання на цю політику конфіденційності.</li>
        <li>Спілкуєтеся з нами іншими способами, включаючи продажі, маркетинг або події.</li>
      </ul>
      <p>Запитання чи зауваження?<br> Читання цієї політику конфіденційності допоможе вам зрозуміти ваші права та обовʼязки щодо конфіденційності. Ми несемо відповідальність за прийняття рішень про те, як обробляється ваша особиста інформація. Якщо ви не згодні з нашими політиками і практиками, будь ласка, не використовуйте наші Послуги. Якщо у вас є будь-які питання чи зауваження, будь ласка, зв&rsquo;яжіться з нами за адресою press.ksv@post.mil.gov.ua.</p>
      <h3>ОСНОВНІ ПУНКТИ</h3>
      <p>Цей огляд надає основні пункти з нашої політики конфіденційності, але ви можете дізнатися більше про будь-яку з цих тем, перейшовши за посиланнями після кожного основного пункту або використовуючи наше змістовне меню нижче для пошуку потрібного розділу.</p>
      <ol>
        <li>Яку особисту інформацію ми обробляємо? Коли ви відвідуєте, використовуєте або навігуєте наш сайт, ми можемо обробляти особисту інформацію залежно від того, як ви взаємодієте з нами, виборів, які ви робите, та функцій, які ви використовуєте.&nbsp;</li>
        <li>Чи обробляємо ми чутливу особисту інформацію? Деяка інформація може вважатися "спеціальною" або "чутливою" в певних юрисдикціях, наприклад, ваше расове чи етнічне походження, сексуальна орієнтація та релігійні переконання. Ми не обробляємо чутливу особисту інформацію.</li>
        <li>Чи збираємо ми інформацію від третіх осіб? Ми не збираємо інформацію від третіх осіб.</li>
        <li>Як ми обробляємо вашу інформацію? Ми обробляємо вашу інформацію для надання, покращення та адміністрування наших Послуг, для комунікації з вами, для забезпечення безпеки та запобігання шахрайству, а також для дотримання закону. Ми також можемо обробляти вашу інформацію для інших цілей за вашою згодою. Ми обробляємо вашу інформацію лише тоді, коли маємо дійсну правову підставу для цього. Дізнайтеся більше про те, як ми обробляємо вашу інформацію.</li>
        <li>В яких ситуаціях і з якими сторонами ми ділимося особистою інформацією? Ми можемо ділитися інформацією в певних ситуаціях і з певними третіми сторонами.&nbsp;</li>
        <li>Як ми зберігаємо вашу інформацію в безпеці? Ми маємо відповідні організаційні та технічні процеси та процедури для захисту вашої особистої інформації. Однак жодна електронна передача через Інтернет або технології зберігання інформації не можуть бути гарантовано 100% безпечними, тому ми не можемо обіцяти або гарантувати, що хакери, кіберзлочинці або інші несанкціоновані треті особи не зможуть обійти нашу безпеку та неправомірно зібрати, отримати доступ до, вкрасти або змінити вашу інформацію. Дізнайтеся більше про те, як ми зберігаємо вашу інформацію в безпеці.</li>
        <li>Які у вас права? Залежно від того, де ви знаходитесь географічно, застосовне законодавство про конфіденційність може надати вам певні права щодо вашої особистої інформації. Дізнайтеся більше про ваші права на конфіденційність.</li>
        <li>Як ви можете реалізувати свої права? Найзручніший спосіб реалізувати свої права &mdash; подати запит на доступ до даних суб&rsquo;єкта або зв&rsquo;язатися з нами. Ми розглянемо та відреагуємо на будь-який запит відповідно до застосовних законів про захист даних.</li>
      </ol>
      <h3>ЗМІСТ</h3>
      <ol class="zmist">
        <li>
          ЯКУ ІНФОРМАЦІЮ МИ ЗБИРАЄМО?</li>
        <li>ЯК МИ ОБРОБЛЯЄМО ВАШУ ІНФОРМАЦІЮ?</li>
        <li>КОЛИ І З ЯКИМИ СТОРОНАМИ МИ ДІЛИМОСЯ ВАШОЮ ОСОБИСТОЮ ІНФОРМАЦІЄЮ?</li>
        <li>ЧИ ВИКОРИСТОВУЄМО МИ COOKIES І ІНШІ ТЕХНОЛОГІЇ ВІДСЛІДКОВУВАННЯ?</li>
        <li>ЯК ДОВГО МИ ЗБЕРІГАЄМО ВАШУ ІНФОРМАЦІЮ?</li>
        <li>ЯК МИ ЗБЕРІГАЄМО ВАШУ ІНФОРМАЦІЮ В БЕЗПЕЦІ?</li>
        <li>ЧИ ЗБИРАЄМО МИ ІНФОРМАЦІЮ ВІД НЕПОВНОЛІТНІХ?</li>
        <li>ЯКІ ВАШІ ПРАВА НА КОНФІДЕНЦІЙНІСТЬ?</li>
        <li>КЕРУВАННЯ ФУНКЦІЯМИ "НЕ ВІДСЛІДКОВУВАТИ"</li>
        <li>ЧИ ВНОСИМО МИ ОНОВЛЕННЯ ДО ЦЬОГО ЗВІТУ?</li>
        <li>ЯК ВИ МОЖЕТЕ ЗВ&rsquo;ЯЗАТИСЯ З НАМИ ЩОДО ЦЬОГО ЗВІТУ?</li>
        <li>ЯК ВИ МОЖЕТЕ ОГЛЯНУТИ, ОНОВИТИ АБО ВИДАЛИТИ ДАНІ, ЯКІ МИ ЗБИРАЄМО ВІД ВАС?</li>
      </ol>
        <h3>1. ЯКУ ІНФОРМАЦІЮ МИ ЗБИРАЄМО?</h3>
      <p>Особиста інформація, яку ви надаєте нам<br /><br />Коротко: Ми збираємо особисту інформацію, яку ви нам надаєте.</p>
      <p>Ми збираємо особисту інформацію, яку ви добровільно надаєте нам, коли ви висловлюєте інтерес до отримання інформації про нас або заповнюєте анкету, або іншим чином контактуєте з нами.</p>
      <p>Особиста інформація, яку ми збираємо, залежить від контексту вашої взаємодії з нами, виборів, які ви робите, і продуктів та функцій, які ви використовуєте. Особиста інформація, яку ми збираємо, може включати наступне:</p>
      <ul>
        <li>імена</li>
        <li>номери телефонів</li>
        <li>адреси електронної пошти</li>
        <li>контактні або аутентифікаційні дані</li>
      </ul>
      <p>Чутлива інформація. Ми не обробляємо чутливу інформацію.</p>
      <p>Вся особиста інформація, яку ви надаєте нам, має бути правдивою, повною та точною, і ви повинні повідомити нас про будь-які зміни цієї особистої інформації.</p>
      <p>Інформація, яка автоматично збирається<br /><br />Коротко: Деяка інформація &mdash; така як ваша IP-адреса та/або характеристики браузера і пристрою &mdash; збирається автоматично, коли ви відвідуєте наші Послуги.</p>
      <p>Ми автоматично збираємо певну інформацію, коли ви відвідуєте, використовуєте або навігуєте нашими Послугами. Ця інформація не розкриває вашу конкретну особистість (таку як ваше ім&rsquo;я чи контактна інформація), але може включати інформацію про пристрій і використання, таку як ваша IP-адреса, характеристики браузера і пристрою, операційну систему, мовні уподобання, реферуючі URL-адреси, назву пристрою, країну, розташування, інформацію про те, як і коли ви використовуєте наші Послуги, і іншу технічну інформацію. Ця інформація в основному потрібна для підтримки безпеки і роботи наших Послуг, а також для наших внутрішніх аналітичних і звітних цілей.</p>
      <p>Як і багато бізнесів, ми також збираємо інформацію за допомогою cookies і подібних технологій. Ви можете дізнатися більше про це у нашому <a href="/cookie-policy">Звіті про cookies</a>.</p>
      <p>Зібрана нами інформація включає:</p>
      <ul>
        <li>Дані журналів і використання. Дані журналів і використання &mdash; це інформація про послуги, діагностика, використання і продуктивність, яку наші сервери автоматично збирають, коли ви отримуєте доступ до наших Послуг або використовуєте їх, і яку ми записуємо в журнальних файлах. Залежно від того, як ви взаємодієте з нами, ці дані журналів можуть включати вашу IP-адресу, інформацію про пристрій, тип браузера і налаштування, а також інформацію про вашу активність у Послугах (таку як дати/часові мітки, пов&rsquo;язані з вашим використанням, переглянуті сторінки і файли, пошуки та інші дії, які ви здійснюєте, такі як використані функції), інформацію про події пристрою (таку як системна активність, звіти про помилки і налаштування обладнання).</li>
        <li>Дані пристрою. Ми збираємо дані про пристрій, такі як інформація про ваш комп&rsquo;ютер, телефон, планшет або інший пристрій, який ви використовуєте для доступу до Послуг. Залежно від використовуваного пристрою, ці дані можуть включати інформацію таку як ваша IP-адреса (або проксі-сервер), ідентифікаційні номери пристрою і додатків, розташування, тип браузера, модель обладнання, постачальник Інтернет-послуг і/або мобільний оператор, операційна система і інформація про конфігурацію системи.</li>
        <li>Дані про розташування. Ми збираємо дані про розташування, такі як інформація про місцезнаходження вашого пристрою, яка може бути точною або неточною. Кількість зібраної інформації залежить від типу і налаштувань пристрою, який ви використовуєте для доступу до Послуг. Наприклад, ми можемо використовувати GPS і інші технології для збору даних про геолокацію, які вказують на ваше поточне місцезнаходження (на основі вашої IP-адреси). Ви можете відмовитися від дозволу на збір цієї інформації, відмовившись від доступу до інформації або відключивши налаштування розташування на вашому пристрої. Однак, якщо ви виберете відмову, ви можете не мати можливості використовувати певні аспекти Послуг.</li>
      </ul>
      <h3>2. ЯК МИ ОБРОБЛЯЄМО ВАШУ ІНФОРМАЦІЮ?</h3>
      <p>Коротко: Ми обробляємо вашу інформацію для надання, покращення і адміністрування наших Послуг, для комунікації з вами, для забезпечення безпеки і запобігання шахрайству, а також для дотримання закону. Ми також можемо обробляти вашу інформацію для інших цілей за вашою згодою.</p>
      <p>Ми обробляємо вашу особисту інформацію з різних причин, залежно від того, як ви взаємодієте з нашими Послугами, включаючи:</p>
      <ul>
        <li>Для надання та полегшення надання послуг користувачу. Ми можемо обробляти вашу інформацію, щоб надати вам запитувану послугу.</li>
        <li>Для виконання та управління вашими замовленнями. Ми можемо обробляти вашу інформацію для виконання і управління вашими замовленнями, платежами, поверненнями та обмінами, здійсненими через Послуги.</li>
        <li>Для забезпечення комунікації між користувачами. Ми можемо обробляти вашу інформацію, якщо ви виберете використання будь-яких наших пропозицій, які дозволяють спілкуватися з іншими користувачами.</li>
        <li>Для запиту відгуків. Ми можемо обробляти вашу інформацію, коли це необхідно для запиту відгуків і для зв&rsquo;язку з вами про використання наших Послуг.</li>
        <li>Для надання таргетованої реклами. Ми можемо обробляти вашу інформацію для розробки та відображення персоналізованого контенту і реклами, адаптованої до ваших інтересів, розташування та інших факторів. Для додаткової інформації дивіться наш Звіт про cookies: <a href="https://docs.google.com/document/d/13KIIpzqiSm3BAuLtGkm8OybA7qowmhhm1L78Q5iXHzs/edit?usp=sharing">Link</a>.</li>
        <li>Для захисту наших Послуг. Ми можемо обробляти вашу інформацію в рамках наших зусиль для забезпечення безпеки та захисту наших Послуг, включаючи моніторинг і запобігання шахрайству.</li>
        <li>Для оцінки та покращення наших Послуг, продуктів, маркетингу та вашого досвіду. Ми можемо обробляти вашу інформацію, коли вважаємо це необхідним для виявлення тенденцій використання, визначення ефективності наших рекламних кампаній і для оцінки та покращення наших Послуг, продуктів, маркетингу та вашого досвіду.</li>
        <li>Для визначення ефективності наших маркетингових і рекламних кампаній. Ми можемо обробляти вашу інформацію, щоб краще зрозуміти, як надавати маркетингові та рекламні кампанії, які є найбільш актуальними для вас.</li>
      </ul>
      <h3>3. КОЛИ І З ЯКИМИ СТОРОНАМИ МИ ДІЛИМОСЯ ВАШОЮ ОСОБИСТОЮ ІНФОРМАЦІЄЮ?</h3>
      <p>Коротко: Ми можемо ділитися інформацією в певних ситуаціях, описаних у цьому розділі та/або з наступними третіми сторонами.</p>
      <p>Ми можемо потребувати поділитися вашою особистою інформацією в наступних ситуаціях:</p>
      <ul>
        <li>Бізнес-передачі. Ми можемо поділитися або передати вашу інформацію у зв&rsquo;язку з будь-яким злиттям, продажем активів компанії, фінансуванням або придбанням всього або частини нашого бізнесу іншою компанією.</li>
      </ul>
      <h3>4. ЧИ ВИКОРИСТОВУЄМО МИ COOKIES І ІНШІ ТЕХНОЛОГІЇ ВІДСЛІДКОВУВАННЯ?</h3>
      <p>Коротко: Ми можемо використовувати cookies та інші технології відслідковування для збору та зберігання вашої інформації.</p>
      <p>Ми можемо використовувати cookies та подібні технології відслідковування (такі як веб-маяки та пікселі) для збору інформації, коли ви взаємодієте з нашими Послугами. Деякі онлайн-технології відслідковування допомагають нам підтримувати безпеку наших Послуг, запобігати збоям, виправляти помилки, зберігати ваші уподобання і допомагати з основними функціями сайту.</p>
      <p>Ми також дозволяємо третім сторонам та постачальникам послуг використовувати онлайн-технології відслідковування на наших Послугах для аналітики та реклами, зокрема для допомоги в управлінні та відображенні реклами, для адаптації реклами до ваших інтересів або для надсилання нагадувань про покинуті кошики (залежно від ваших уподобань щодо комунікації). Треті сторони та постачальники послуг використовують свою технологію для надання реклами про продукти та послуги, що відповідають вашим інтересам, які можуть з&rsquo;являтися як на наших Послугах, так і на інших вебсайтах.</p>
      <p>Конкретна інформація про те, як ми використовуємо такі технології та як ви можете відмовитися від певних cookies, наведена у нашому Звіті про cookies: <a href="https://docs.google.com/document/d/13KIIpzqiSm3BAuLtGkm8OybA7qowmhhm1L78Q5iXHzs/edit?usp=sharing">Link</a>.</p>
      <h3>5. ЯК ДОВГО МИ ЗБЕРІГАЄМО ВАШУ ІНФОРМАЦІЮ?</h3>
      <p>Коротко: Ми зберігаємо вашу інформацію стільки, скільки це необхідно для досягнення цілей, викладених у цьому Звіті про конфіденційність, якщо інше не передбачено законом.</p>
      <p>Ми будемо зберігати вашу особисту інформацію лише на той час, який є необхідним для цілей, викладених у цьому Звіті про конфіденційність, якщо тільки довший термін зберігання не вимагатиметься або не дозволяється законом (наприклад, вимоги щодо податків, бухгалтерського обліку чи інших юридичних вимог).</p>
      <p>Коли у нас немає триваючої законної потреби обробляти вашу особисту інформацію, ми або видалимо, або анонімізуємо таку інформацію, або, якщо це неможливо (наприклад, через те, що ваша особиста інформація зберігається в резервних архівах), тоді ми будемо надійно зберігати вашу особисту інформацію і ізолювати її від подальшої обробки до моменту можливості видалення.</p>
      <h3>6. ЯК МИ ЗАХИЩАЄМО ВАШУ ІНФОРМАЦІЮ?</h3>
      <p>Коротко: Ми прагнемо захистити вашу особисту інформацію через систему організаційних і технічних заходів безпеки.</p>
      <p>Ми впровадили відповідні та розумні технічні та організаційні заходи безпеки, щоб захистити безпеку будь-якої особистої інформації, яку ми обробляємо. Однак, незважаючи на наші засоби захисту і зусилля для забезпечення вашої безпеки, жодна електронна передача через Інтернет або технологія зберігання інформації не може бути гарантовано 100% безпечною, тому ми не можемо обіцяти чи гарантувати, що хакери, кіберзлочинці чи інші несанкціоновані треті сторони не зможуть подолати нашу безпеку і неправомірно зібрати, отримати доступ до, вкрасти або змінити вашу інформацію. Хоча ми зробимо все можливе, щоб захистити вашу особисту інформацію, передача особистої інформації до і з наших Послуг відбувається на ваш ризик. Ви повинні отримувати доступ до Послуг лише в безпечному середовищі.</p>
      <h3>7. ЧИ ЗБИРАЄМО МИ ІНФОРМАЦІЮ ВІД НЕПОВНОЛІТНІХ?</h3>
      <p>Коротко: Ми свідомо не збираємо дані від або не здійснюємо маркетинг для дітей до 18 років.</p>
      <p>Ми свідомо не збираємо, не запитуємо дані від або не здійснюємо маркетинг для дітей до 18 років, а також не продаємо таку особисту інформацію. Використовуючи Послуги, ви підтверджуєте, що вам щонайменше 18 років або що ви є батьком чи опікуном такої особи і надаєте згоду на використання Послуг такою особою. Якщо ми дізнаємося, що особиста інформація користувачів, які менше 18 років, була зібрана, ми деактивуємо обліковий запис і вживемо розумних заходів для швидкого видалення таких даних з наших записів. Якщо ви дізнаєтеся про будь-які дані, які ми могли зібрати від дітей до 18 років, будь ласка, зв&rsquo;яжіться з нами за адресою press.ksv@post.mil.gov.ua.</p>
      <h3>8. ЯКІ ВАШІ ПРАВА НА КОНФІДЕНЦІЙНІСТЬ?</h3>
      <p>Коротко: Ви можете переглядати, змінювати або припиняти дію вашого облікового запису в будь-який час, залежно від вашої країни, провінції або штату.</p>
      <p>Відкликання вашої згоди: Якщо ми покладаємося на вашу згоду для обробки вашої особистої інформації, яка може бути вираженою та/або припущеною згідно з чинним законодавством, ви маєте право відкликати вашу згоду в будь-який час. Ви можете відкликати свою згоду в будь-який час, зв&rsquo;язавшись з нами, використовуючи контактні дані, наведенні в розділі "ЯК МОЖНА ЗВ&rsquo;ЯЗАТИСЯ З НАМИ ЩОДО ЦЬОГО ПОВІДОМЛЕННЯ?" нижче.</p>
      <p>Однак, зверніть увагу, що це не вплине на законність обробки до моменту її відкликання, а також, коли це дозволено законом, не вплине на обробку вашої особистої інформації, що проводиться на основі законних підстав, окрім згоди.</p>
      <p>Cookies та подібні технології: Більшість веб-браузерів за замовчуванням налаштовані на прийом cookies. Якщо ви віддаєте перевагу, ви зазвичай можете налаштувати ваш браузер на видалення cookies і відмову від cookies. Якщо ви виберете видалення або відмову від cookies, це може вплинути на певні функції або сервіси наших Послуг. Для отримання додаткової інформації дивіться наш Звіт про cookies: <a href="https://docs.google.com/document/d/13KIIpzqiSm3BAuLtGkm8OybA7qowmhhm1L78Q5iXHzs/edit?usp=sharing">Link</a>.</p>
      <p>Якщо у вас є питання або коментарі щодо ваших прав на конфіденційність, ви можете надіслати нам електронного листа за адресою <a class="disable-underline" href="mailto:press.ksv@post.mil.gov.ua">press.ksv@post.mil.gov.ua</a>.</p>
      <h3>9. КОНТРОЛЬ ЗА ФУНКЦІЯМИ НЕ ВІДСЛІДКОВУВАТИ</h3>
      <p>Більшість веб-браузерів та деякі мобільні операційні системи і мобільні додатки включають функцію або налаштування "Не відслідковувати" ("DNT"), яку ви можете активувати, щоб сигналізувати про ваше бажання не допускати моніторинг і збір даних про вашу онлайн-активність. На даний момент жоден єдиний технологічний стандарт для розпізнавання і реалізації сигналів DNT не був остаточно затверджений. Як наслідок, ми наразі не реагуємо на сигнали браузерів DNT або будь-який інший механізм, який автоматично передає ваш вибір не бути відслідкованим онлайн. Якщо в майбутньому буде прийнятий стандарт для онлайн-стеження, якого ми повинні будемо дотримуватись, ми повідомимо вас про цю практику в оновленій версії цього Звіту про конфіденційність.</p>
      <h3>10. ЧИ ВНОСИМО МИ ОНОВЛЕННЯ ДО ЦЬОГО ПОВІДОМЛЕННЯ?</h3>
      <p>Коротко: Так, ми будемо оновлювати це повідомлення за необхідності для дотримання відповідних законів.</p>
      <p>Ми можемо періодично оновлювати цей Звіт про конфіденційність. Оновлена версія буде зазначена оновленою датою "Оновлено" вгорі цього Звіту про конфіденційність. Якщо ми внесемо суттєві зміни в цей Звіт про конфіденційність, ми можемо повідомити вас або шляхом явного розміщення повідомлення про такі зміни, або шляхом безпосереднього надсилання вам повідомлення. Ми рекомендуємо вам регулярно переглядати цей Звіт про конфіденційність, щоб бути в курсі того, як ми захищаємо вашу інформацію.</p>
      <h3>11. ЯК МОЖНА ЗВ&rsquo;ЯЗАТИСЯ З НАМИ ЩОДО ЦЬОГО ПОВІДОМЛЕННЯ?</h3>
      <p>Якщо у вас є питання або коментарі щодо цього Звіту про конфіденційність, будь ласка, зв&rsquo;яжіться з нами за адресою press.ksv@post.mil.gov.ua.</p>
      <h3>12. ЯК ВИ МОЖЕТЕ ПЕРЕГЛЯНУТИ, ОНОВИТИ ЧИ УДАЛИТИ ДАНІ, ЯКІ МИ ЗБИРАЄМО ПРО ВАС?</h3>
      <p>Згідно з чинним законодавством вашої країни, ви можете мати право на доступ до особистої інформації, яку ми збираємо про вас, деталі про те, як ми її обробляємо, виправлення неточностей або видалення вашої особистої інформації. Ви також можете мати право відкликати вашу згоду на обробку вашої особистої інформації. Ці права можуть бути обмежені в деяких випадках відповідно до чинного законодавства. Щоб подати запит на перегляд, оновлення або видалення вашої особистої інформації, будь ласка, заповніть та подайте запит на доступ до даних.</p>
    </div>
  </section>
  <custom-footer />
</template>

<script>
import CustomFooter from "@/components/Footer.vue";
import CustomHeader from "@/components/Header.vue";

export default {
  name: 'privacy-policy',
  components: {CustomHeader, CustomFooter},
}
</script>

<style lang="scss">
.privacy {
  background-color: #6a653a;
  padding-top: 100px;
  min-height: 100vh;

  @media (max-width: 767px) {
    padding-left: 10px;
    padding-right: 10px;
  }
  h2 {
    font-size: 36px;
    @media (max-width: 767px) {
      font-size: 28px!important;
    }
  }

  li {
    margin-left: 20px;
  }

  h1, h2, h3, h4, h5, p, ul, li, a {
    color: #F3F3EF;
    max-width: 100%;
  }

  .container {
    display: flex;
    flex-direction: column;
    row-gap: 25px;
  }

  .zmist {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

}
</style>
