<template>
  <section class="guarantee">
    <div class="container">
      <h2>{{ title }}</h2>
      <div class="guarantee__list">
        <div
          class="guarantee__item"
          v-for="item in guaranteeContent"
          :key="item.id"
        >
          <div class="guarantee__top">
            <img class="guarantee__icon" :src="getImagePath(item.icon)" alt="icon">
            <h4>{{ item.title }}</h4>
          </div>
          <p class="guarantee__description">
            {{ item.description }}
          </p>
        </div>
      </div>
      <img class="guarantee__image" src="@/assets/images/guarantee.png" alt="icon" />
      <div class="guarantee__button">
        <button-connect />
      </div>
    </div>
  </section>
</template>

<script>
import ButtonConnect from "@/components/UI/CustomButtonAdd.vue";

export default {
  name: 'guarantee-block',
  components: {ButtonConnect},
  data() {
    return {
      title: 'Гарантії від Зсу',
      guaranteeContent: [
        {
          icon: 'guarantee-1.png',
          title: 'Повне\n забезпечення',
          description: 'Військова служба гарантує стабільне фінансове забезпечення від 20+ до 120+ тис грн на місяць, повне речове та продовольче забезпечення (забезпечення формою, взуттям, спорядженням та повноцінне харчування від українських виробників), безкоштовні медичні послуги та повний соціальний пакет.',
        },
        {
          icon: 'guarantee-2.png',
          title: 'Професійна підготовка та навчання',
          description: 'Забезпечуємо підготовку для всіх без винятку військовослужбовців на фондах найбільш розвинутої мережі навчальних центрів та полігонів в Україні. Базова – 30 днів, фахова – до 2 і більше місяців. Маємо найрозвинутішу мережу вищих військових навчальних закладів. ',
        },
        {
          icon: 'guarantee-3.png',
          title: `Кар\'єрний розвиток`,
          description: 'Забезпечуємо найширші у Збройних Силах України можливості кар\'єрного розвитку для найбільш професійних, сміливих та підготовлених військовослужбовців на посадах усіх рівнів.',
        },
      ],
    };
  },
  methods: {
    getImagePath(fileName) {
      return require(`@/assets/images/${fileName}`)
    }
  }
}
</script>

<style lang="scss" scoped>
.guarantee {
  background: #F3F3EF;
  padding: 80px 0;

  @media (max-width: 767px) {
    padding: 22px 20px;
  }

  .container {
    @media (max-width: 767px) {
      display: flex;
      flex-direction: column;
    }
  }

  &__item {
    padding: 24px 0;

    &:not(:last-child) {
      border-bottom: 1px solid #6A653A;
    }

    &:first-child,
    &:last-child {
      h4 {
        max-width: 200px;
      }
    }
  }

  &__top {
    display: flex;
    gap: 15px;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 15px;
  }

  &__icon {
    max-width: 40px;
    width: 100%;
  }

  &__description {
    font-size: 24px;
    font-weight: 400;
    line-height: 28.8px;

    @media (max-width: 767px) {
      font-size: 18px;
      line-height: 22.4px;
    }
  }

  &__list {
    @media (max-width: 767px) {
      order: 2;
    }
  }

  &__image {
    max-width: 100%;

    @media (max-width: 767px) {
      order: 1;
    }
  }

  &__button {
    display: flex;
    justify-content: center;
    margin-top: 32px;

    @media (max-width: 767px) {
      order: 3;
    }
  }
}
</style>