<template>
  <custom-header />
  <section class="cookie">
    <div class="container">
      <h1>Політика використання файлів Cookie</h1>
      <p>Дата набуття чинності: 01.09.2024</p>
      <p>Ця Політика використання файлів cookie пояснює, як ми використовуємо файли cookie та інші подібні технології для збору та зберігання інформації під час вашого відвідування нашого веб-сайту.</p>
      <h4>Що таке файли cookie?</h4>
      <p>Файли cookie — це невеликі текстові файли, які зберігаються на вашому пристрої під час відвідування веб-сайту. Вони дозволяють веб-сайту запам'ятовувати ваші дії та налаштування (наприклад, мову, вхідні дані та інші налаштування) протягом певного часу, щоб вам не потрібно було вводити ці дані знову під час наступного відвідування сайту або переходу між сторінками.</p>
      <h4>Які дані ми збираємо за допомогою файлів cookie?</h4>
      <p>Наш веб-сайт використовує файли cookie для збору та зберігання таких даних:</p>
      <ul>
        <li>Ім'я та прізвище;</li>
        <li>Електронна пошта;</li>
        <li>Номер телефону;</li>
        <li>Згода на обробку даних;</li>
        <li>Рік народження;</li>
        <li>IP-адреса;</li>
        <li>Доменне ім’я, яке ви запитали;</li>
        <li>Назва вашого інтернет-провайдера (ISP), якщо це дозволяється налаштуваннями вашого провайдера;</li>
        <li>Дата і час вашого відвідування;</li>
        <li>Тривалість вашого сеансу;</li>
        <li>Сторінки, які ви відвідували;</li>
        <li>Скільки разів ви заходили на наш сайт протягом місяця;</li>
        <li>URL-адреси файлів, які ви переглядаєте, та інформація, пов’язана з ними;</li>
        <li>Веб-сайт, з якого ви перейшли на наш сайт;</li>
        <li>Операційна система вашого комп'ютера.</li>
      </ul>
      <h4>Як ми використовуємо файли cookie?</h4>
      <p>Ми використовуємо файли cookie для таких цілей:</p>
      <ul>
        <li>Збір аналітичної інформації про відвідування веб-сайту для покращення його роботи.</li>
        <li>Відстеження кількості відвідувань і сеансів для покращення користувацького досвіду.</li>
        <li>Обробка ваших заявок та форм, які ви заповнюєте на нашому сайті.</li>
      </ul>
      <h4>Google Analytics</h4>
      <p>Ми використовуємо Google Analytics для відстеження трафіку нашого веб-сайту. Google Analytics зберігає файли cookie, які допомагають нам аналізувати, як ви використовуєте наш веб-сайт. Термін зберігання даних користувачів становить 26 місяців.</p>
      <p>Інформація, яку збирає Google Analytics, може включати:</p>
      <ul>
        <li>IP-адресу;</li>
        <li>Тривалість сеансів;</li>
        <li>Дата і час вашого відвідування;</li>
        <li>Сторінки, які ви переглядали;</li>
        <li>URL-адреса веб-сайту, з якого ви перейшли на наш сайт.</li>
      </ul>
      <h4>Як керувати файлами cookie?</h4>
      <p>Ви можете контролювати та/або видаляти файли cookie на вашому пристрої. Для цього скористайтесь налаштуваннями вашого браузера. Ви можете налаштувати свій браузер так, щоб блокувати файли cookie або повідомляти вас про їх використання. Однак, зверніть увагу, що деякі функції нашого веб-сайту можуть не працювати належним чином без файлів cookie.</p>
      <h4>Зміни до цієї політики</h4>
      <p>Ми можемо час від часу оновлювати цю Політику використання файлів cookie. Оновлення будуть публікуватися на цій сторінці із зазначенням дати набуття чинності змін.</p>
      <p>Якщо у вас виникли питання щодо цієї Політики, звертайтесь до нас за контактними даними, вказаними на веб-сайті.</p>
    </div>
  </section>
  <custom-footer />
</template>

<script>
import CustomFooter from "@/components/Footer.vue";
import CustomHeader from "@/components/Header.vue";

export default {
  name: 'cookie-policy',
  components: {CustomHeader, CustomFooter},
}
</script>

<style lang="scss">
.cookie {
  background-color: #6a653a;
  padding-top: 100px;
  min-height: 100vh;

  @media (max-width: 767px) {
    padding-left: 10px;
    padding-right: 10px;
  }

  h1 {
    font-size: 36px;
  }

  li {
    margin-left: 20px;
  }

  h1, h2, h3, h4, h5, p, ul, li {
    color: #F3F3EF;
    max-width: 100%;
  }

  .container {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
  }

}
</style>
