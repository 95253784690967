<template>
  <section
    id="form"
    class="form"
  >
    <div class="container">
      <h2>{{ title }}</h2>
      <p class="form__description">
        {{ description }}
      </p>
      <img class="form__img" src="@/assets/images/form-bg.png" alt="bg" />
      <form class="form__body" id="formBody" @submit.prevent="sendForm">
        <div class="form__part">
          <div class="block form__main-info">
            <div class="form__input--block">
              <p class="form__subtitle required">Прізвище та Ім’я</p>
              <input
                type="text"
                v-model="nameSurname"
                class="input form__input"
                placeholder="Імʼя та прізвище"
                required
              />
            </div>
            <div class="form__input--block">
              <p class="form__subtitle required">Номер телефону</p>
              <input
                type="text"
                v-model="phone"
                class="input form__input"
                placeholder="Номер телефону"
                required
              />
            </div>
            <div class="form__input--block">
              <p class="form__subtitle">Електронна пошта</p>
              <input
                type="email"
                v-model="email"
                class="input form__input"
                placeholder="Електронна пошта"
              />
            </div>
            <div class="form__input--block">
              <p class="form__subtitle">Ваш вік</p>
              <multiselect
                v-model="age"
                class="input form__select"
                :searchable="false"
                :options="ageArr"
                placeholder="Ваш вік"
              />
            </div>
          </div>
        </div>

        <div class="form__part form__is-zsu">
          <div class="form__input-block radio-btn">
            <p class="form__subtitle">Чи перебуваєте Ви в лавах ЗСУ або в інших складових Сил оборони України?</p>
            <label class="container">Так
              <input type="radio" name="military" value="так">
              <span class="checkmark"></span>
            </label>
            <label class="container">Ні
              <input type="radio" name="military" value="ні" checked>
              <span class="checkmark"></span>
            </label>
          </div>
        </div>

        <div class="form__part form__teams">
          <p class="form__subtitle">
            Обраний підрозділ, рід військ (необовʼязково)
          </p>
          <div class="block">
            <multiselect
              v-model="selectTeam"
              class="input form__select"
              :searchable="true"
              :options="teamsArr"
              placeholder="Оберіть бригаду"
              @change="handleChange"
            />
            <input
              v-if="selectTeam === 'Інша бригада'"
              type="text"
              v-model="selectTeamText"
              class="input form__input"
              placeholder="Введіть назву бригади"
              required
            />
          </div>
        </div>

        <div class="form__part form__goal">
          <p class="form__subtitle">Яка мета подання Вами анкети?(можна обрати кілька варіантів відповідей)</p>
          <div class="form__input-block checkboxes">
            <label class="container">
              Призов на службу за контрактом
              <input v-model="selectedOptions" value="Призов за службу за контрактом" type="checkbox" checked="checked">
              <span class="checkmark"></span>
            </label>
          </div>
          <div class="form__input-block checkboxes">
            <label class="container">
              Призов на службу по мобілізації
              <input v-model="selectedOptions" value="Призов на службу по мобілізації" type="checkbox">
              <span class="checkmark"></span>
            </label>
          </div>
          <div class="form__input-block checkboxes">
            <label class="container">
              Переведення між підрозділами Сухопутних військ
              <input v-model="selectedOptions" value="Переведення між підрозділами Сухопутних військ" type="checkbox">
              <span class="checkmark"></span>
            </label>
          </div>
          <div class="form__input-block checkboxes">
            <label class="container">
              Переведення з інших складових Сил оборони
              <input v-model="selectedOptions" value="Переведення з інших складових Сил оборони" type="checkbox">
              <span class="checkmark"></span>
            </label>
          </div>
          <div class="form__input-block checkboxes">
            <label class="container">
              Отримання консультації з питань рекрутингу
              <input v-model="selectedOptions" value="Отримання консультації з питань рекрутингу" type="checkbox">
              <span class="checkmark"></span>
            </label>
          </div>
        </div>

        <div class="form__part form__cv">
          <p class="form__subtitle">Надайте своє резюме/CV для найкращого підбору пропозиції (необов’язково)</p>
          <div class="block">
            <label class="input-file">
              <input type="file" name="file" @change="handleFileChange">
              <span v-if="!file">{{ downloadCV }}</span>
              <span v-else>Завантажено</span>
            </label>
            <div class="block">
              <p v-if="file">{{ file.name }}</p>
              <div
                v-if="file"
                class="form__delete-cv"
                @click="deleteCV"
              >
                <img src="@/assets/images/close_btn.svg" alt="icon" />
              </div>
            </div>
          </div>
        </div>
        <div class="form__part">
          <div class="form__input-block checkboxes">
            <label class="container">
              Погоджуюсь на обробку персональних даних в цілях рекрутингу до підрозділів Сухопутних військ
              <input name="obrobka" type="checkbox" value="так" checked="checked" required>
              <span class="checkmark"></span>
            </label>
          </div>
          <button type="submit">Долучитися</button>
        </div>
      </form>
    </div>
  </section>

  <div v-if="isModalOpen" class="modal">
    <div class="modal__container">
      <h3>{{ isModalText }}</h3>
      <button @click="closeModal">Закрити</button>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import axios from "axios";
import Papa from "papaparse";
export default {
  name: 'form-custom',
  components: {Multiselect},
  props: {
    team: {
      type: String,
    }
  },
  data() {
    return {
      title: 'Анкета',
      description: 'Заповни анкету та отримай супровід та індивідуальну консультацію на всіх етапах',
      downloadCV: 'Завантажити CV',
      selectTeam: '',
      selectSubTeam: this.team,
      teamsArr: [],
      subTeamsArr: [],
      selectedOptions: [],
      ageArr: [
        'до 18',
        '18-59',
        'більше 60'
      ],
      nameSurname: '',
      phone: '',
      email: '',
      age: '18-59',
      file: '',
      csvData: [],
      isModalOpen: false,
      isModalText: '',
      fileName: '',
      selectTeamText: '',
    };
  },
  mounted() {
    this.getNameList();
  },
  computed: {
    selectTeamComputed: {
      get() {
        return this.team;
      },
      set(value) {
        this.$emit('update:team', value);
      }
    }
  },
  methods: {
    handleChange(event) {
      const selectedValue = event.target.value;
      this.updateSelectTeam(selectedValue);
    },
    updateSelectTeam(value) {
      this.selectTeam = value;
      this.selectTeamComputed = value;
    },
    deleteCV() {
      this.file = '';
    },
    closeModal() {
      window.scrollTo({top: 0})
      this.selectTeam = '';
      this.nameSurname = '';
      this.phone = '';
      this.email = '';
      this.selectedOptions = [];
      this.selectTeamText = '';
      this.isModalOpen = false;
    },
    parseCsv(csv) {
      const result = Papa.parse(csv, {
        header: true,
        delimiter: ',',
        quoteChar: '"',
      });

      return result.data;
    },
    async getNameList() {
      try {
        const response = await axios.get('/teams.csv');
        const csv = response.data;
        this.csvData = this.parseCsv(csv);
        this.teamsArr = this.csvData.map(item => item.name);
        this.teamsArr.push('Інша бригада')
      } catch (error) {
        console.error('Error loading CSV data:', error);
      }
    },
    async sendForm() {
      if (this.file) {
        const formData = new FormData();
        formData.append('file', this.file);

        try {
          const response = await axios.post('upload.php', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
          console.log('Файл загружен успешно', response);
          this.fileName = response.data;

        } catch (error) {
          console.error('Ошибка при загрузке файла', error);
        }
      }
      let form = document.getElementById('formBody');
      let data = new FormData(form);
      const fullUrlWithPath = `${window.location.protocol}//${window.location.host}`;
      let file = this.file ? `${fullUrlWithPath}/uploads/${this.fileName}` : "ні"

      this.selectTeam = this.selectTeam === 'Інша бригада' ? this.selectTeamText : this.selectTeam;

      axios.post(`https://script.google.com/macros/s/AKfycbw1VCohvAyq_Ldjwsi5E69NQyma85e8Q5d_qpuieNmcB7XJLdh48vb2pDNxidaSx_MevA/exec?p1=${this.nameSurname}&p2=${this.phone}&p3=${this.email}&p4=${this.age}&p5=${data.get('military')}&p6=${this.selectTeam}&p7=${this.selectedOptions.join(', ')}&p8=${file}&p9=${data.get('obrobka')}`)
        .then(response => {
          this.isModalText = 'Ваші дані успішно відправлено, гарного вам дня!'
          this.isModalOpen = true;
        })
        .catch(error => {
          this.isModalText = 'Щось пішло не так, спробуйте пізніше'
          this.isModalOpen = true;
          console.error('помилка:', error);
        });
    },
    handleFileChange(event) {
      this.file = event.target.files[0];
      if (this.file) {
        console.log('file', this.file);
      }
    },
  },
  watch: {
    team(newVal) {
      this.selectTeam = newVal;
    }
  }
}
</script>

<style lang="scss" scoped>
$background-color: #6A653A;

.form {
  padding: 20px 0 150px;
  background: #F3F3EF;

  @media (max-width: 767px) {
    padding: 20px 22px;
    box-sizing: border-box;
  }

  &__description {
    font-size: 24px;
    line-height: 28.8px;
  }

  .block {
    display: flex;
    gap: 24px;
    align-items: center;
    flex-wrap: wrap;
    position: relative;

    @media (max-width: 767px) {
      flex-direction: column;
      width: 100%;
    }
  }

  &__main-info {
    width: 800px;
  }

  &__part {
    padding: 24px 0;
    border-top: 1px solid #6A653A;
  }

  &__subtitle {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 14px;
    position: relative;

    &.required {
      &:after {
        font-size: 11px;
        content: "*обовʼязкове поле";
        position: absolute;
        bottom: 0;
        right: 0;
        color: #ff2a00;
      }
    }
  }

  &__img {
    max-width: 100%;
    margin: 24px 0;
  }

  &__delete-cv {
    position: absolute;
    top: -3px;
    right: -30px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    border: 2px solid #6a653a;

    @media (max-width: 767px) {
      right: 0;
    }

    @media (min-width: 768px) and (max-width: 1127px) {
      right: -40px;
    }

    img {
      width: 12px;
    }
  }

  &__teams {
    .block {
      display: flex;
      gap: 24px;
      align-items: center;
    }
  }
  &__input {
    padding-left: 15px;
    box-sizing: border-box;
  }
  &__select,
  &__input {
    width: 360px;

    @media (max-width: 767px) {
      width: 100%;
    }
  }

  &__input--block {
    @media (max-width: 767px) {
      width: 100%;
    }
  }

  button {
    border: none;
    background: #F39200;
    width: 264px;
    height: 46px;
    color: white;
    text-transform: uppercase;
    font-size: 18px;
    margin-top: 28px;

    @media (max-width: 767px) {
      width: 100%;
    }
  }
}

.checkboxes {
  a {
    color: #fcfcfc;
  }
  .container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 18px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    line-height: 27px;

    @media (max-width: 767px) {
      white-space: pre-wrap;
      width: 100%;
      box-sizing: border-box;
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
      &:checked {
        ~ {
          .checkmark {
            border: none;
            background-color: $background-color;
            &:after {
              display: block;
            }
          }
        }
      }
    }
    &:hover {
      input {
        ~ {
          .checkmark {
            background-color: $background-color;
          }
        }
      }
    }
    .checkmark {
      &:after {
        left: 9px;
        top: 5px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: $background-color;
    &:after {
      content: "";
      position: absolute;
      display: none;
    }
  }
}

.radio-btn {
  margin-bottom: 0;
  label {
    margin-bottom: 5px;
  }
  .container {
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    font-size: 18px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    overflow: hidden;
    height: 29px;
    line-height: 27px;
    input {
      opacity: 0;
      cursor: pointer;
      &:checked {
        ~ {
          .checkmark {
            background-color: $background-color;
            border: none;
            &:after {
              display: block;
            }
          }
        }
      }
    }
    &:hover {
      input {
        ~ {
          .checkmark {
            background-color: $background-color;
          }
        }
      }
    }
    .checkmark {
      &:after {
        top: 6px;
        left: 6px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: white;
      }
    }
  }
  .checkmark {
    position: absolute;
    top: 3px;
    left: 0;
    height: 20px;
    width: 20px;
    border: 1px solid #fcfcfc;
    background-color: $background-color;
    border-radius: 50%;
    &:after {
      content: "";
      position: absolute;
      display: none;
    }
  }
}

.input-file {
  position: relative;
  display: inline-block;
}
.input-file span {
  position: relative;
  cursor: pointer;
  text-decoration: none;
  font-size: 14px;
  color: #6A653A;
  border: 4px solid #6A653A;
  background: #F3F3EF;
  height: 46px;
  box-sizing: border-box;
  margin: 0;
  width: 264px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-family: "UAF Sans Bold";
}
.input-file input[type=file] {
  position: absolute;
  z-index: -1;
  opacity: 0;
  display: block;
  width: 0;
  height: 0;
}

.input {
  border: 1px solid #6A653A;
  border-radius: 0!important;
  height: 55px;
  font-size: 22px!important;
  color: #6A653A!important;
}
</style>

<style>
.form__teams .multiselect__option span {
  position: relative;
  top: 0;
  white-space: break-spaces;
  line-height: 24px;
}

.form__teams .multiselect__option {
  height: auto!important;
}

.form__teams .multiselect__single {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.multiselect__input {
  font-size: 22px!important;
  font-family: "UAF Sans"!important;
  color: #6A653A!important;
  margin-bottom: 0!important;
  margin-top: 7px;
  margin-left: -5px;
}

.modal {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, .6);
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;

  .modal__container {
    min-width: 400px;
    min-height: 200px;
    padding: 20px 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: #F3F3EF;
    gap: 40px;

    button {
      width: 80%;
      height: 60px;
      border: none;
      background: #F39200;
      color: white;
      font-size: 22px;
      cursor: pointer;
    }
  }
}
</style>