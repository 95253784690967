<template>
  <section class="join-us">
    <div class="container">
      <h2>Цікаво доєднатись?</h2>
      <div class="join-us__list">
        <div
          v-for="item in joinUsContent"
          :key="item.id"
          :class="'join-us__item ' + item.class"
          @click="isRocketForm(item.class)"
        >
          <div class="join-us__blur"></div>

          <img class="join-us__arrow" src="@/assets/images/arrow-white.png" alt="icon" />
          <div class="join-us__text">
            <h5>{{ item.title }}</h5>
            <p class="join-us__description">
              {{ item.description }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'join-us',
  props: ['rocketForm'],
  data() {
    return {
      joinUsContent: [
        {
          class: 'join-us__1',
          title: 'Drone force',
          description: 'Набір та підготовка пілотів БпЛА'
        },
        {
          class: 'join-us__2',
          title: 'ГРІМ І ВОГОНЬ',
          description: 'Стань Богом війни у підрозділах ракетних військ і артилерії'
        },
        {
          class: 'join-us__3',
          title: 'Обери посаду',
          description: 'Подай заявку до рекрутингового центру Сухопутних військ'
        },
        {
          class: 'join-us__4',
          title: 'Будь собою',
          description: 'Більше вакансій Сухопутних військ на національній платформі рекрутингу'
        },
      ],
    };
  },
  methods: {
    isRocketForm(item) {
      if (item === 'join-us__1') {
        window.open('https://landforces.mil.gov.ua/df', '_blank');
      } else if (item === 'join-us__4'){
        window.open('https://army.gov.ua/?sector=suhoputniviyska', '_blank');
      } else if (item === 'join-us__2') {
        this.$emit('update', true);
      } else {
        this.$emit('update', false);
      }

      if (item !== 'join-us__1' && item !== 'join-us__4') {
        const element = document.getElementById('form');
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.join-us {
  padding: 70px 0 60px;

  @media (max-width: 767px) {
    padding: 22px 20px;
  }

  h2 {
    @media (max-width: 1127px) {
      margin-bottom: 70px;
      position: relative;
      max-width: 100vw;
    }

    @media (max-width: 1127px) {
      &:after {
        content: url("@/assets/images/swipe.svg");
        width: 161px;
        height: 32px;
        position: absolute;
        right: 0;
        bottom: -45px;
      }
    }
  }

  &__list {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;

    @media (max-width: 1127px) {
      overflow-x: scroll;
      overflow-y: hidden;
      gap: 24px;
    }
  }

  &__1 {
    background: url("@/assets/images/join-us-1.png");
  }

  &__2 {
    background: url("@/assets/images/join-us-2.jpeg");
  }

  &__3 {
    background: url("@/assets/images/join-us-3.png");
  }

  &__4 {
    background: url("@/assets/images/join-us-4.png");
  }

  &__item {
    height: 500px;
    min-height: 264px;
    width: 23.5%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;

    @media (max-width: 1127px) {
      min-width: 334px;
    }
  }

  &__arrow {
    position: absolute;
    top: 35px;
    right: 35px;
    width: 24px;
  }

  &__text {
    position: absolute;
    bottom: 30px;
    left: 35px;
    height: 200px;
    color: white;
  }

  h5 {
    color: white;
    text-transform: uppercase;
  }

  &__description {
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    color: white;
    margin-top: 10px;
    margin-right: 30px;
  }

  &__blur {
    background: linear-gradient(180deg, rgba(12, 11, 2, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
    width: 100%;
    height: 100%;
  }
}
</style>