<template>
  <custom-header />
  <main-screen />
  <about-block />
  <structure-block />
  <teams-list
    @update="choiseTeam"
  />
  <guarantee-block />
  <join-us
    @update="accessRocketForm"
  />
  <section
    class="tabs"
    :class="{'bg-rocket': isRocketForm}"
  >
    <div class="container">
      <div
        class="tabs__btn"
        @click="isRocketForm = false"
        :class="{'active': !isRocketForm}"
      >
        Обери посаду
      </div>
      <div
        class="tabs__btn"
        :class="{'active': isRocketForm}"
        @click="isRocketForm = true"
      >
        Ставай Богом війни
      </div>
    </div>
  </section>
  <form-custom
    v-if="!isRocketForm"
    :team="choosedTeam"
  />
  <form-custom-rocket
    v-else
  />
  <custom-footer />
</template>
<script>
import CustomHeader from "@/components/Header.vue";
import MainScreen from "@/components/MainScreen.vue";
import AboutBlock from "@/components/About.vue";
import GuaranteeBlock from "@/components/Guarantee.vue";
import JoinUs from "@/components/Join.vue";
import CustomFooter from "@/components/Footer.vue";
import StructureBlock from "@/components/Structure.vue";
import TeamsList from "@/components/Teams.vue";
import FormCustom from "@/components/Form.vue";
import FormCustomRocket from "@/components/FormRocket.vue";

export default {
  name: "index-page",
  components: {
    FormCustomRocket,
    FormCustom,
    TeamsList,
    StructureBlock,
    CustomFooter,
    JoinUs,
    GuaranteeBlock,
    AboutBlock,
    MainScreen,
    CustomHeader
  },
  data() {
    return {
      isRocketForm: false,
      choosedTeam: '',
    };
  },
  methods: {
    choiseTeam(data) {
      this.choosedTeam = data.name;
      if (this.isRocketForm) {
        this.isRocketForm = false;
      }
      const element = document.getElementById('form');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    },
    accessRocketForm(data) {
      this.isRocketForm = data;
    }
  }
}
</script>
<style lang="scss" scoped>
.tabs {
  padding: 60px 0 0px;

  background: #F3F3EF;

  .container {
    display: flex;
    gap: 20px;
    align-items: center;
    @media (max-width: 767px) {
      padding: 0 15px;
    }
  }

  &__btn {
    margin: 10px 20px;
    font-size: 24px;
    cursor: pointer;

    @media (max-width: 767px) {
      text-align: center;
      font-size: 20px;
    }

    &:first-child {
      margin: 10px 20px 10px 0;
    }

    &.active {
      border-bottom: 2px solid #6A653A;
    }
  }

  &.bg-rocket {
    background: #FBF7E5;
  }
}
</style>
<style lang="scss">
.mobile {
  @media (min-width: 1128px) {
    display: none!important;
  }
  @media (max-width: 1127px) {
    display: block;
  }
}

.desktop {
  display: block;
  @media (max-width: 1127px) {
    display: none!important;
  }
}
</style>