<template>
  <section class="main-screen">
    <div class="container">
      <div class="main-screen__content">
        <h1 class="main-screen__title">
          <span class="main-screen__orange">Перемога</span>
          Йде за сильними
          <span class="main-screen__star">*</span>
        </h1>
        <button-connect />
      </div>
      <div class="main-screen__description">
        {{ disclaimer }}
      </div>
    </div>
  </section>
</template>

<script>
import ButtonConnect from "@/components/UI/CustomButtonAdd.vue";

export default {
  name: 'main-screen',
  components: {ButtonConnect},
  data() {
    return {
      disclaimer: '*Victoria sequitur fortes (Перемога йде за сильними) – девіз Командування Сухопутних військ'
    };
  }
}
</script>

<style lang="scss" scoped>
.main-screen {
  background: url("@/assets/images/main-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  min-width: 100vw;
  height: auto;

  @media (max-width: 767px) {
    background: url("@/assets/images/main-bg-mob.png");
    background-size: cover;
    background-repeat: no-repeat;
  }

  @media (min-width: 768px) and (max-width: 1127px) {
    background-position-x: center;
  }

  .container {
    height: 800px;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;

    @media (max-width: 767px) {
      justify-content: end;
      height: 600px;
    }
  }

  &__content {
    width: 588px;

    @media (max-width: 767px) {
      width: 100%;
      text-align: center;
      margin-bottom: 44px;
    }

    h1 {
      color: white;
      text-transform: uppercase;
      position: relative;
      margin-bottom: 40px;

      @media (max-width: 767px) {
        margin-bottom: 20px;
      }
    }
  }

  &__orange {
    color: #F39200;
    font-family: "UAF Sans SB";

    @media (max-width: 767px) {
      font-size: 34px;
      line-height: 40.8px;
      width: 100%;
      display: block;
    }
  }

  &__star {
    font-size: 22px;
    position: absolute;
    top: 55px;

    @media (max-width: 767px) {
      top: 30px;
      color: white;
    }
  }

  &__description {
    color: white;
    width: 445px;
    position: absolute;
    bottom: 50px;

    .main-screen__star {
      top: -5px;
      left: 0;
    }

    @media (max-width: 767px) {
      position: unset;
      width: calc(100% - 40px);
      margin: 0 auto 20px;
    }
  }

  .button__connect {
    @media (max-width: 767px) {
      width: calc(100% - 40px);
      margin: 0 auto;
    }
  }
}
</style>