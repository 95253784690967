<template>
  <section class="teams">
    <div class="container">
      <h2>{{ title }}</h2>
      <div class="filters__block">
        <input
          type="text"
          v-model="searchQuery"
          class="input teams__input"
          placeholder="Пошук бригади"
        />
        <multiselect
          v-model="selectFilter"
          style="display: none"
          class="input teams__select"
          :searchable="false"
          :options="filterForm"
          placeholder="Приналежність"
        />
      </div>
      <div class="teams__list">
          <div
            v-for="item in currentPageData"
            :key="item.id"
            class="teams__item"
          >
            <div class="teams__header">
              <img :src="getImageTeamPath(item.images)" alt="icon" />
              <p class="teams__title">
                {{ item.name }}
              </p>
            </div>
            <div class="teams__description--container">
              <div class="teams__description--wrapper">
                {{ item.description }}
              </div>
            </div>
            <div class="teams__social">
              <a
                target="_blank"
                :href="item.facebook"
                :class="{'disabled': item.facebook.length < 1}"
              >
                <img
                  :src="getImagePath(item.facebook, 'facebook')"
                  alt="icon"
                />
              </a>
              <a
                target="_blank"
                :href="item.instagram"
                :class="{'disabled': item.instagram.length < 1}"
              >
                <img
                  :src="getImagePath(item.instagram, 'instagram')"
                  alt="icon"
                />
              </a>
              <a
                target="_blank"
                :href="item.telegram"
                :class="{'disabled': item.telegram.length < 1}"
              >
                <img
                  :src="getImagePath(item.telegram, 'telegram')"
                  alt="icon"
                />
              </a>
              <a
                target="_blank"
                :href="item.youtube"
                :class="{'disabled': item.youtube.length < 1}"
              >
                <img
                  :src="getImagePath(item.youtube, 'youtube')"
                  alt="icon"
                />
              </a>
              <a
                target="_blank"
                :href="item.tiktok"
                :class="{'disabled': item.tiktok.length < 1}"
              >
                <img
                  :src="getImagePath(item.tiktok, 'tiktok')"
                  alt="icon"
                />
              </a>
              <a
                target="_blank"
                :href="item.site"
                :class="{'disabled': item.site.length < 1}"
              >
                <img
                  :src="getImagePath(item.site, 'site')"
                  alt="icon"
                />
              </a>
            </div>
            <a v-if="item.isButton != 2" :class="{'opacity': item.isButton == 0}" @click="choiseTeam(item)" class="teams__button">Долучитися</a>
            <a v-else target="_blank" :href="item.site" class="teams__button">Дізнатися більше</a>
          </div>
        </div>
      </div>
      <div class="pagination">
        <button
          v-for="(page, pageIndex) in paginatedData"
          :key="'pagination-' + pageIndex"
          @click="currentPage = pageIndex"
          :class="{'active': currentPage === pageIndex}"
        >
          {{ pageIndex + 1 }}
        </button>
      </div>
  </section>
</template>

<script>
import Papa from 'papaparse';
import axios from 'axios';
import Multiselect from "vue-multiselect";
export default {
  name: 'teams-list',
  components: {Multiselect},
  data() {
    return {
      searchQuery: '',
      title: 'Наші бригади та частини',
      csvData: [],
      filterForm: [],
      selectFilter: '',
      itemsPerPage: 8,
      currentPage: 0
    };
  },
  mounted() {
    this.loadCsvData();
  },
  computed: {
    filteredData() {
      let data = this.csvData;

      if (this.searchQuery) {
        const query = this.searchQuery.toLowerCase();
        data = data.filter(item => {
          return item.name.toLowerCase().includes(query);
        });
      }

      if (this.selectFilter) {
        data = data.filter(item => item.form === this.selectFilter);
      }

      if (this.searchQuery || this.selectFilter) {
        this.currentPage = 0;
      }

      return data;
    },
    paginatedData() {
      const pages = [];
      if (window.innerWidth < 767) {
        this.itemsPerPage = 3;
      } else if (window.innerWidth > 768 && window.innerWidth < 1127) {
        this.itemsPerPage = 6;
      }
      for (let i = 0; i < this.filteredData.length; i += this.itemsPerPage) {
        pages.push(this.filteredData.slice(i, i + this.itemsPerPage));
      }
      return pages;
    },
    currentPageData() {
      return this.paginatedData[this.currentPage] || [];
    }
  },
  methods: {
    choiseTeam(item) {
      this.$emit('update', item);
    },
    getImageTeamPath(item) {
      return require(`@/assets/images/teams_logo/${item}`);
    },
    getImagePath(item, platform) {
      let status = item.length > 0 ? true : false;
      return require(`@/assets/images/${platform}-${status}.png`);
    },
    async loadCsvData() {
      try {
        const response = await axios.get('/teams.csv');
        const csv = response.data;
        this.csvData = this.parseCsv(csv);
        this.cntList = 0.5 + this.csvData.length / 8;
        this.filterForm = [...new Set(this.csvData.map(item => item.form))];
      } catch (error) {
        console.error('Error loading CSV data:', error);
      }
    },
    parseCsv(csv) {
      const result = Papa.parse(csv, {
        header: true,
        delimiter: ',',
        quoteChar: '"',
      });

      return result.data;
    }
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style lang="scss" scoped>
.teams {
  padding: 70px 0;

  h2 {
    max-width: 344px
  }

  @media (max-width: 767px) {
    padding: 22px 20px;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    max-height: 908px;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: 50px;
    @media (max-width: 767px) {
      max-height: 645px;
    }

    @media (min-width: 768px) and (max-width: 1127px) {
      padding-bottom: 75px;
      row-gap: 20px;
      column-gap: 9px;
    }

  }

  &__item {
    width: 264px;
    height: 434px;
    border: 1px solid #6A653A;
    padding: 20px 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    position: relative;
    
    @media (max-width: 767px) {
      width: 100%;
      height: 210px;
    }

    @media (min-width: 768px) and (max-width: 1127px) {
      width: 240px;
      height: 455px;
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    @media (max-width: 767px) {
      flex-direction: unset;
      align-items: center;
      width: 100%;
      gap: 10px;
    }

    img {
      margin: 0 auto;
      height: 130px;

      @media (max-width: 767px) {
        width: 40px;
        margin: 0;
        height: auto;
      }
    }
  }

  &__title {
    font-size: 18px;
    font-weight: 500;
    line-height: 26.4px;
    align-self: flex-start;
    margin-top: 14px;

    @media (max-width: 767px) {
      margin-top: 6px;
      font-size: 16px;
    }
  }

  &__description {
    &--container {
      max-width: 264px;
      height: 50px;
      box-sizing: border-box;

      @media (max-width: 767px) {
        display: none;
      }

      @media (min-width: 768px) and (max-width: 1127px) {
        max-width: 230px;
      }
    }

    &--wrapper {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      position: relative;
      z-index: 1;
      cursor: pointer;
      padding: 10px;
      transition: all 0.3s ease;
    }

    &--wrapper:hover {
      max-height: none; /* Раскрыть текст полностью */
      position: absolute;
      z-index: 10;
      background-color: #fff; /* Изменение фона при наведении */
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      white-space: normal;
      left: 0;
    }

  }

  &__social {
    align-self: flex-start;
    img {
      max-width: 24px;
      margin-right: 12px;
    }
  }

  &__button {
    width: 100%;
    height: 46px;
    border: 2px solid #F39200;
    background: transparent;
    transition: .3s;
    text-transform: uppercase;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 14px;

    @media (max-width: 767px) {
      margin-top: 0;
    }

    &:hover {
      background: #F39200;
      color: white;
      transition: .3s;
    }
  }
  &__input {
    width: 464px;
    padding-left: 12px;
    position: relative;

    @media (max-width: 767px) {
      width: calc(100% - 14px);
    }
  }

  &__select {
    width: 360px;
    @media (max-width: 767px) {
      width: unset
    }
  }
}

a.disabled {
  pointer-events: none;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;

  @media (max-width: 767px) {
    flex-wrap: wrap;
  }

  button {
    width: 30px;
    height: 30px;
    border: none;
    border-radius: 5px;
    cursor: pointer;

    &.active {
      background: #6A653A;
      color: white;
    }
  }
}

.input {
  border: 1px solid #6A653A;
  border-radius: 0!important;
  height: 55px;
  font-size: 22px!important;
  color: #6A653A!important;
}

.filters {
  &__block {
    display: flex;
    gap: 25px;
    margin-bottom: 30px;
    position: relative;

    @media (max-width: 767px) {
      flex-direction: column;
      gap: 10px;
    }

    &:after {
      content: " ";
      background: url("@/assets/images/search.svg");
      position: absolute;
      top: 14px;
      left: 435px;
      width: 26px;
      height: 26px;

      @media (max-width: 767px) {
        right: 25px;
        left: unset;
      }

      @media (min-width: 768px) and (max-width: 1127px) {
        left: 50%;
      }
    }
  }
}

.opacity {
  opacity: 0;
  margin-top: -14px;
}
</style>

<style lang="scss">
.multiselect__tags,
.multiselect__single {
  border-radius: 0!important;
  font-size: 22px!important;
  color: #6A653A!important;
  border: none!important;
}

.multiselect__tags {
  min-height: 55px!important;
}

.multiselect__single {
  margin-top: 10px;
  margin-bottom: 0 !important;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.form {
  .multiselect__single {
    margin-top: 10px;
    margin-bottom: 0!important;
  }
}

.multiselect__placeholder {
  color: #6A653A;
  display: inline-block;
  margin-bottom: 0px;
  padding-top: 7px;
}

.multiselect__select {
  height: 55px!important;
}

.multiselect__option {
  border-bottom: 1px solid #6A653A;
  &--highlight {
    background: #F3F3EF!important;
    &::after {
      background: transparent;
      color: transparent;
    }
  }
  &--selected {
    &::after {
      content: url("@/assets/images/close_btn.svg");
      right: 10px;
      top: 11px;
    }
    &.multiselect__option--highlight {
      &::after {
        background: transparent;
        content: url("@/assets/images/close_btn.svg");
        right: 10px;
        top: 11px;
      }
    }
  }

  span {
    position: relative;
    white-space: normal;
    line-height: 27px;
    width: 80%;
    display: block;
  }
}

.multiselect__content-wrapper {
  border: 1px solid;
  border-radius: 0;
  border-top: none;
  width: 362px;
  margin-left: -1px;

  @media (max-width: 767px) {
    width: calc(100% + 2px);
  }
}
</style>