<template>
  <section class="about" id="about">
    <div class="container">
      <h2>{{ title }}</h2>
      <p class="about__description">
        {{ description }}
      </p>
      <div class="about__list">
        <div
          class="about__item"
          v-for="item in aboutContent"
          :key="item.id"
        >
          <div class="about__date">{{ item.date }}</div>
          <img class="about__img" :src="getImagePath(item.img)" alt="image" />
          <div class="about__text">
            <h3>{{ item.h3 }}</h3>
            <p class="about__sub-description">
              {{ item.description }}
            </p>
            <img class="about__arrow" src="@/assets/images/arrow.png" alt="icon" />
          </div>
        </div>

      </div>
      <div class="about__button">
        <button-connect />
      </div>
    </div>
  </section>
</template>

<script>
import ButtonConnect from "@/components/UI/CustomButtonAdd.vue";

export default {
  name: 'about-block',
  components: {ButtonConnect},
  data() {
    return {
      title: 'Про військо',
      description: 'Головний носій бойових спроможностей Збройних Сил України. Найчисленніший самостійний вид, призначений для ведення бойових дій переважно на суходолі. Сухопутні війська - ключова рушійна сила всіх українських перемог на полі бою.',
      aboutContent: [
        {
          date: 'до 2014',
          img: 'about-1.png',
          h3: 'ЗСУ до 2014 року',
          description: 'Сухопутні війська були сформовані у складі Збройних Сил України у 1996 році. Проте від 1991 року загальновійськові підрозділи та з\'єднання ЗСУ брали участь у стримуванні будь-якої гіпотетичної агресії проти України. Воїни Сухопутних військ гідно брали участь у всіх міжнародних миротворчих операціях та місіях, що є важливим зовнішньополітичним здобутком нашої держави.'
        },
        {
          date: '20.02.2014 — зима 2022',
          img: 'about-2.png',
          h3: 'Оборона України з 2014 року',
          description: 'Наші підрозділи звільняли десятки населених пунктів на Сході України: Лисичанськ, Сєвєродонецьк, Рубіжне, Лиман, Авдіївка, Марʼїнка тощо. До 2022 року багато в чому завдяки воїнам Сухопутних військ наші позиції на лінії розмежування в ООС залишалися непорушними. '
        },
        {
          date: '24.02.2022 – дотепер',
          img: 'about-3.png',
          h3: 'Відбиття повномасштабного вторгнення росії',
          description: 'Сухопутні війська відіграють ключову роль у боротьбі проти російської агресії: оборона Києва, звільнення Півночі, Харківський контрнаступ, звільнення правобережжя Херсонщини, стримання окупантів на всій лінії фронту. Ключові результати роботи ракетних військ та артилерії, танкових військ, засобів РЕБ, БпЛА — це досягнення наших воїнів!'
        },
      ],
    };
  },
  methods: {
    getImagePath(fileName) {
      return require(`@/assets/images/${fileName}`)
    }
  }
}
</script>

<style lang="scss" scoped>
.about {
  padding: 70px 0;
  background: #F3F3EF;

  @media (max-width: 899px) {
    padding: 22px 20px;
  }

  h3 {
    @media (max-width: 767px) {
      height: 50px;
    }
    @media (min-width: 768px) and (max-width: 1000px) {
      width: 85%;
    }
  }

  &__description {
    font-size: 24px;
    line-height: 28.8px;
    color: #6A653A;
    margin-bottom: 25px;

    @media (max-width: 767px) {
      font-size: 18px;
      line-height: 21.6px;
      margin-bottom: 60px;
      position: relative;
      &:after {
        content: url("@/assets/images/swipe.svg");
        width: 161px;
        height: 32px;
        position: absolute;
        right: 0;
        bottom: -45px;
      }
    }
  }

  &__list {
    @media (max-width: 767px) {
      display: flex;
      overflow-y: hidden;
      overflow-x: scroll;
    }
  }

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-bottom: 1px solid #000000;
    padding: 20px 0;
    position: relative;

    @media (min-width: 768px) and (max-width: 1000px) {
      gap: 20px;
    }

    &:first-child {
      border-top: 1px solid #000000;
      @media (max-width: 767px) {
        border-top: 1px solid #6A653A;
      }
    }

    @media (max-width: 767px) {
      border: 1px solid #6A653A;
      flex-direction: column;
      width: 335px;
      padding: 65px 20px 20px;
      gap: 10px;

      &:not(:last-child) {
        border-right: 0.5px solid #6A653A;
      }
    }
  }

  &__img {
    max-width: 264px;
    height: auto;
    object-fit: contain;

    @media (max-width: 767px) {
      order: 1;
      height: 200px;
      max-width: unset;
      width: auto;
      object-fit: cover;
    }
  }

  &__date {
    min-width: 167px;
    @media (max-width: 767px) {
      order: 2;
    }

    @media (min-width: 768px) and (max-width: 1127px) {
      min-width: 87px;
    }
  }

  &__text {
    max-width: 617px;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 18px;
    @media (max-width: 767px) {
      order: 3;
      margin-top: 14px;
      gap: 48px;
    }
  }

  &__sub-description {
    font-size: 18px;
    font-weight: 400;
    line-height: 21.6px;
  }

  &__arrow {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 24px;
  }

  &__button {
    display: flex;
    justify-content: center;
    margin-top: 32px;
  }
}
</style>