import { createRouter, createWebHistory } from 'vue-router'
import IndexPage from '../views/IndexPage.vue'
import PrivacyPolicyPage from "@/views/PrivacyPolicyPage.vue";
import CookiePolicyPage from "@/views/CookiePolicyPage.vue";

const routes = [
  {
    path: '/',
    name: 'index',
    component: IndexPage
  },
  {
    path: '/privacy-policy',
    name: 'privacy',
    component: PrivacyPolicyPage
  },
  {
    path: '/cookie-policy',
    name: 'cookie',
    component: CookiePolicyPage
  },
  {
    path: '/df',
    beforeEnter() {
      window.location.href = '/path-to-html-file.html';
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
