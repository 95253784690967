<template>
  <footer id="footer" class="footer">
    <div class="container">
      <img class="footer__logo desktop" src="@/assets/images/logo_mf.png" alt="logo" />
      <img class="footer__logo mobile" src="@/assets/images/logo_mf-mob.png" alt="logo" />
      <div class="footer__info">
        <div class="footer__item">
          <div
            class="footer__contact"
            v-for="phone in phoneArr"
            :key="phone.id"
          >
            <img class="footer__icon" src="@/assets/images/phone.png" alt="icon" />
            <a :href="'tel:' + phone.phone">{{ phone.phone }} {{ phone.title }}</a>
          </div>
          <p class="footer__label">Для медіа:</p>
          <div
            class="footer__contact"
          >
            <img class="footer__icon" src="@/assets/images/email.png" alt="icon" />
            <a href="mailto:press.ksv@post.mil.gov.ua">press.ksv@post.mil.gov.ua</a>
          </div>
        </div>
        <div class="footer__item">
          <div class="footer__social">
            <p class="footer__label">Соціальні мережі:</p>
            <a target="_blank" href="https://www.facebook.com/UALandForces"><img class="footer__icon" src="@/assets/images/fb.png" alt="icon" /></a>
            <a target="_blank" href="https://www.instagram.com/ukrainianlandforces"><img class="footer__icon" src="@/assets/images/inst.png" alt="icon" /></a>
            <a target="_blank" href="https://t.me/landforcesofukraine"><img class="footer__icon" src="@/assets/images/tg.png" alt="icon" /></a>
            <a target="_blank" href="https://youtube.com/@landforcesofukraine"><img class="footer__icon" src="@/assets/images/yt.png" alt="icon" /></a>
            <a target="_blank" href="https://www.tiktok.com/@landforcesofukraineoff"><img class="footer__icon" src="@/assets/images/tt.png" alt="icon" /></a>
          </div>
          <p class="footer__label">
            Корисні посилання:
          </p>
          <div class="footer__social">
            <a target="_blank" href="https://www.mil.gov.ua/">
              <img class="footer__link" src="@/assets/images/mou.png" alt="icon" />
            </a>
            <a target="_blank" href="https://www.zsu.gov.ua/">
              <img class="footer__link" src="@/assets/images/zsu-footer.svg" alt="icon" />
            </a>
          </div>
        </div>
      </div>
      <nav class="footer__menu">
        <ul>
          <li><a href="/#about">Про військо</a></li>
          <li><a href="/#structure">Структура</a></li>
          <li><a target="_blank" href="/df">DroneForce</a></li>
          <li><a href="/#contact">Контакти</a></li>
          <li><a href="/privacy-policy">Політика конфіденційності</a></li>
          <li><a href="/cookie-policy">Політика cookie</a></li>
        </ul>
      </nav>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'custom-footer',
  data() {
    return {
      phoneArr: [
        {
          phone: '0 800 500 410',
          title: '(загальні питання)',
        },
        {
          phone: '0 800 503 696',
          title: '(рекрутинг)',
        },
        {
          phone: '0 800 301 937',
          title: '(питання комплектування)',
        },
      ],
    };
  }
}
</script>

<style lang="scss" scoped>
.footer {
  padding: 45px 0;
  background: #6A653A;

  .container {
    border-top: 2px solid #787456;
    padding-top: 24px;
    box-sizing: border-box;
    width: calc(100% - 42px);
  }

  &__logo {
    max-width: 160px;

    @media (max-width: 767px) {
      max-width: 110px;
      margin: 0 auto;
    }
  }

  &__info {
    padding: 24px 0 46px;
    border-bottom: 1px solid #787456;
    display: flex;

    @media (max-width: 767px) {
      flex-direction: column;
      padding: 36px 0 22px;
      box-sizing: border-box;
      width: 100%;
      gap: 30px;
    }
  }

  &__item {
    width: 50%;

    @media (max-width: 767px) {
      width: 100%;
    }
  }

  &__menu {
    ul {
      display: flex;
      gap: 20px;
      justify-content: center;
      margin: 25px 0 0;

      @media (max-width: 767px) {
        flex-direction: column;
      }
    }
  }

  &__contact {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 8px;
  }

  &__icon {
    width: 24px;
  }

  &__label {
    font-size: 14px;
    color: white;
    margin: 15px 0 8px;
  }

  &__social {
    display: flex;
    gap: 14px;
    align-items: center;

    .footer__label {
      margin: 0;
      width: 85px;
    }
  }

  &__link {
    width: 98px;
  }

  a {
    color: white;
    text-decoration: none;
    font-size: 14px;
    line-height: 17px;
    white-space: nowrap;

    &:last-child {
      .footer__link {
        max-width: 88px;
        margin-top: -10px
      }
    }
  }
}
</style>